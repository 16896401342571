import { signOut } from "next-auth/react";
import { clearSessionStorage } from "services/Api";
import { clearReduxStore, removeCartOrder } from "utils/storage";

export default function useLogout() {
  const logout = async () => {
    clearReduxStore();
    removeCartOrder();
    clearSessionStorage();

    await signOut({
      redirect: true,
      callbackUrl: "/",
    });
  };

  return logout;
}
