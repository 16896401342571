import Footer from "components/common/Footer";

import { useSession } from "next-auth/react";
import { HeaderV2 } from "./HeaderV2/HeaderV2";

const Layout = ({ children }) => {
  const { status } = useSession();
  const isLogged = status === "authenticated";

  return (
    <>
      <HeaderV2 isLogged={isLogged} />
      <main className="flex-1 h-screen pt-[74px]">
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
