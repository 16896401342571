import axios from "axios";
import getConfig from "next/config";
import { getSession } from "next-auth/react";
import { logError } from "lib/lazySentry";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const SENTRY_WHITELIST = [
  { url: /coupon/, status: 404 },
  { url: /credentials/, status: 401 },
  // 3ds default error status
  { url: /payment/, status: 402 },
  { url: "", status: 401 },
  { url: "", status: 403 },
];

export const clearSessionStorage = () =>
  sessionStorage.removeItem("auth_token");

const getSessionToken = async () => {
  if (typeof sessionStorage === "undefined") return await getSession();

  const storageToken = JSON.parse(sessionStorage.getItem("auth_token"));

  const now = new Date();

  if (!storageToken?.expires || now.getTime() > storageToken?.expires) {
    const session = await getSession();

    session && sessionStorage.setItem("auth_token", JSON.stringify(session));

    return session;
  } else return storageToken;
};

export async function fetchV2(url, options = {}) {
  const baseURL =
    serverRuntimeConfig.apiBaseUrl || publicRuntimeConfig.apiBaseUrl;

  return fetch(`${baseURL}/v2${url}`, {
    headers: { "Content-Type": "application/json" },
    ...options,
  })
    .then((res) => res.json())
    .catch((err) => {
      logError(err);
      throw err;
    });
}

export async function fetchExternal(url, options = {}) {
  return fetch(url, options)
    .then((res) => res)
    .catch((err) => {
      logError(err);
      throw err;
    });
}

const api = () => {
  const instance = axios.create({
    baseURL: serverRuntimeConfig.apiBaseUrl || publicRuntimeConfig.apiBaseUrl,
    timeout: 15000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(async (request) => {
    const session = await getSessionToken();

    if (session) {
      request.headers.Authorization = `Token ${session.user.accessToken}`;
    }
    return request;
  });

  instance.interceptors.response.use(undefined, (error) => {
    const status = error.response.status;
    const pathname = error.config.url;
    const isNotWhiteListed = !SENTRY_WHITELIST.some(
      (val) => pathname.match(val.url) && val.status === status
    );
    if (error?.response?.data?.error) {
      isNotWhiteListed && logError(error.response.data.error);
      throw new Error(error.response.data.error);
    }
    isNotWhiteListed && logError(error);
    throw error;
  });

  return instance;
};

export default api();
